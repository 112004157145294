<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <main @click="eventDeligate" class="mx-32 my-10 min-h-screen">
        <section class="flex items-center justify-between px-7 py-4 bg-white rounded shadow">
            <h1 class="text-xl font-bold">Expenditure Requests</h1>
            <div class="flex flex-row-reverse items-start text-sm">
                <div class="flex items-center">
                    <button
                        @click="
                            $router.push({
                                name: fetchingAllExpenditureRequests
                                    ? 'expenditure'
                                    : 'allExpenditures',
                            })
                        "
                        class="w-20 p-2 bg-white rounded text-primaryColor border border-primaryColor"
                    >
                        {{ fetchingAllExpenditureRequests ? 'View Less' : 'View All' }}
                    </button>
                    <button
                        @click="$router.push({ name: 'newExpenditure' })"
                        class="w-20 p-2 bg-primaryColor rounded text-white border border-primaryColor ml-4"
                    >
                        Create
                    </button>
                </div>
                <div class="mr-10 relative">
                    <div
                        @click="getAllDepartments"
                        data-filterdepartment="department-filter"
                        class="flex items-center border border-borderColor rounded px-3.5 py-2"
                    >
                        <span data-filterdepartment="department-filter">Sort by:</span>
                        <span
                            data-filterdepartment="department-filter"
                            class="min-w-12 uppercase px-1"
                            >{{ currentDepartment || 'All' }}</span
                        >
                        <img
                            data-filterdepartment="department-filter"
                            src="@/assets/images/chevron-down-icon-grey.svg"
                        />
                    </div>
                    <ul
                        v-if="showDepartments"
                        class="absolute top-full bg-white shadow-md w-full py-2"
                    >
                        <li
                            @click="$router.push({ name: 'expenditure' })"
                            class="px-2 py-1 hover:bg-borderColor cursor-pointer"
                        >
                            All
                        </li>
                        <li
                            @click="
                                $router.push({
                                    name: 'ExpenditureByDepartment',
                                    params: { department: data.department.toLowerCase() },
                                })
                            "
                            v-for="(data, index) in departments"
                            :key="index"
                            class="px-2 py-1 hover:bg-borderColor cursor-pointer"
                        >
                            {{ data.department }}
                        </li>
                    </ul>
                </div>
                <div class="flex items-center mr-4 w-48 border border-borderColor rounded text-xs">
                    <datePicker v-model="startDate" class="rounded cursor-pointer" />
                    <span class="mx-1 text-fontColor">-</span>
                    <datePicker v-model="endDate" class="rounded cursor-pointer" />
                </div>
                <div class="mr-4">
                    <input
                        v-model="searchTerm"
                        type="text"
                        placeholder="Search..."
                        class="w-56 border border-borderColor rounded p-2"
                        @keypress.enter="searchExpenditureRequests"
                    />
                </div>
            </div>
        </section>
        <LoadingSpinner v-if="loading" />
        <section v-else>
            <div
                v-if="expenditureRequests && expenditureRequests.length"
                class="my-6 px-7 py-4 bg-white rounded shadow"
            >
                <div
                    class="expenditure-items-wrapper flex items-center justify-between uppercase text-xs text-alternativeBgColor px-6 tracking-widest font-bold"
                >
                    <div style="width: 20%" class="flex items-center px-2">
                        <input type="checkbox" class="mr-2 align-text-bottom" />title
                    </div>
                    <div class="px-2">type of purchase</div>
                    <div class="px-2">amount (₦)</div>
                    <div class="px-2">department</div>
                    <div class="px-2">date</div>
                    <div class="px-2">status</div>
                    <img
                        src="@/assets/images/eye-icon.svg"
                        alt="eye icon"
                        style="visibility: hidden"
                    />
                </div>
                <div
                    v-for="request in expenditureRequests"
                    :key="request.id"
                    class="expenditure-items-wrapper flex items-center justify-between capitalize my-2.5 py-4 px-6 border border-borderColor rounded text-sm"
                >
                    <div style="width: 20%" class="flex items-center px-2">
                        <input type="checkbox" class="mr-2 align-text-bottom" />{{
                            request.product_name
                        }}
                    </div>
                    <div class="px-2">
                        {{
                            request.type_of_purchase === 'product_purchase'
                                ? 'product'
                                : 'Non-product'
                        }}
                    </div>
                    <div class="px-2">{{ $filters.currencyUSD(request.total_cost) }}</div>
                    <div class="px-2">{{ request.department }}</div>
                    <div class="px-2">{{ moment(request.created_at).format('D MMM, YYYY') }}</div>
                    <div>
                        <div class="w-24 text-center py-1 px-4 text-xs ">{{ request.status }}</div>
                    </div>
                    <router-link :to="{ name: 'ExpenditureView', params: { id: request.id } }">
                        <img
                            src="@/assets/images/eye-icon.svg"
                            alt="eye icon"
                            class="cursor-pointer w-6"
                        />
                    </router-link>
                </div>
            </div>
            <div
                v-if="expenditureRequests && expenditureRequests.length"
                class="flex mx-auto pt-2 pb-14"
            >
                <div class="w-full flex justify-center">
                    <div
                        class="w-8 h-8 border border-borderColor flex items-center justify-center mx-1 cursor-pointer"
                        :class="{
                            'cursor-not-allowed text-gray-200 bg-gray-100':
                                pageInfo.currentPage === 1,
                        }"
                        @click="getPreviousPage(pageInfo.currentPage - 1)"
                    >
                        ◀︎
                    </div>
                    <div
                        class="w-8 h-8 border border-borderColor flex items-center justify-center mx-1 cursor-pointer"
                        :class="{ 'bg-primaryColor text-white': pageInfo.currentPage === 1 }"
                        @click="getNextPage(1)"
                    >
                        1
                    </div>
                    <div
                        v-if="pageInfo.lastPage > 1"
                        class="w-8 h-8 border border-borderColor flex items-center justify-center mx-1 cursor-pointer"
                        :class="{ 'bg-primaryColor text-white': pageInfo.currentPage === 2 }"
                        @click="getNextPage(2)"
                    >
                        2
                    </div>
                    <div
                        v-if="pageInfo.lastPage > 2"
                        class="w-8 h-8 border border-borderColor flex items-center justify-center mx-1 cursor-pointer"
                        :class="{ 'bg-primaryColor text-white': pageInfo.currentPage === 3 }"
                        @click="getNextPage(3)"
                    >
                        3
                    </div>
                    <div
                        v-if="pageInfo.lastPage > 3"
                        class="w-8 h-8 flex items-center justify-center mx-1"
                    >
                        ...
                    </div>
                    <div
                        v-if="pageInfo.lastPage > 3"
                        class="w-8 h-8 border border-borderColor flex items-center justify-center mx-1 cursor-pointer"
                        :class="{
                            'bg-primaryColor text-white':
                                pageInfo.currentPage === pageInfo.lastPage,
                        }"
                        @click="getNextPage(pageInfo.lastPage)"
                    >
                        {{ pageInfo.lastPage }}
                    </div>
                    <div
                        class="w-8 h-8 border border-borderColor flex items-center justify-center mx-1 cursor-pointer"
                        :class="{
                            'cursor-not-allowed text-gray-200 bg-gray-100':
                                pageInfo.currentPage === pageInfo.lastPage,
                        }"
                        @click="getNextPage(pageInfo.currentPage + 1)"
                    >
                        ►
                    </div>
                </div>
            </div>
        </section>
        <empty-page
            v-if="expenditureRequests && !expenditureRequests.length"
            :routeName="routeName"
        >
            <template v-slot:message>
                No expenditure requests to display.
            </template>
            <template v-slot:button-text>
                Create New Request
            </template>
        </empty-page>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import moment from 'moment'
import DatePicker from 'vue3-datepicker'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import errorFunc from '@/util/error'

export default {
    name: 'expenditureHome',
    components: { DatePicker, ErrorToast },
    data() {
        return {
            searchTerm: null,
            expenditureRequests: null,
            loading: false,
            pageInfo: {},
            moment,
            startDate: null,
            endDate: null,
            errorMessage: null,
            departments: null,
            currentDepartment: null,
            showDepartments: false,
            fetchingAllExpenditureRequests: false,
            fetchingExpenditureRequestByDepartment: false,
            routeName: 'newExpenditure',
        }
    },
    async mounted() {
        if (this.$route.name === 'allExpenditures') {
            this.getAllExpenditureRequests(1)
        } else if (this.$route.name === 'expenditure') {
            this.getExpenditureRequestsApprovedByManagement(1)
        } else if (this.$route.name === 'ExpenditureByDepartment') {
            this.getExpenditureByDepartment()
        }

        const elements = document.querySelectorAll('.v3dp__datepicker')
        elements[0].childNodes[0].setAttribute('placeholder', '12-06-2019')
        elements[1].childNodes[0].setAttribute('placeholder', '24-08-2021')
    },
    watch: {
        endDate() {
            if (this.startDate) {
                this.filterExpenditureByDate()
            } else {
                this.errorMessage = 'Please select start date.'
            }
        },
        startDate() {
            if (this.endDate) {
                this.filterExpenditureByDate()
            }
        },
        $route(nw) {
            if (nw.name === 'allExpenditures') {
                this.getAllExpenditureRequests(1)
            } else if (nw.name === 'expenditure') {
                this.getExpenditureRequestsApprovedByManagement(1)
            } else if (nw.name === 'ExpenditureByDepartment') {
                this.getExpenditureByDepartment(1)
            }
        },
    },
    methods: {
        eventDeligate(event) {
            if (!event.target.dataset.hasOwnProperty('filterdepartment')) {
                this.showDepartments = false
            }
        },
        getNextPage(pageNo) {
            if (pageNo <= this.pageInfo.lastPage) {
                if (this.fetchingAllExpenditureRequests) {
                    this.getAllExpenditureRequests(pageNo)
                } else if (this.fetchingExpenditureRequestByDepartment) {
                    this.getExpenditureByDepartment(pageNo)
                } else {
                    this.getExpenditureRequestsApprovedByManagement(pageNo)
                }
            }
        },
        getPreviousPage(pageNo) {
            if (pageNo > 0) {
                if (this.fetchingAllExpenditureRequests) {
                    this.getAllExpenditureRequests(pageNo)
                } else if (this.fetchingExpenditureRequestByDepartment) {
                    this.getExpenditureByDepartment(pageNo)
                } else {
                    this.getExpenditureRequestsApprovedByManagement(pageNo)
                }
            }
        },
        getExpenditureRequestsApprovedByManagement(pageNo) {
            this.getExpenditureRequests('expenditure/requests/approved/by/management', pageNo)
            this.currentDepartment = null
        },
        getAllExpenditureRequests(pageNo) {
            this.getExpenditureRequests('expenditure/requests', pageNo)
            this.fetchingAllExpenditureRequests = true
            this.currentDepartment = null
        },
        async getExpenditureRequests(url, pageNo) {
            try {
                this.loading = true
                this.fetchingAllExpenditureRequests = false
                this.fetchingExpenditureRequestByDepartment = false

                const compoundURL = this.$route.params.department
                    ? `${url}?department=${this.$route.params.department}&page=${pageNo}`
                    : `${url}?page=${pageNo}`
                const { data } = await Request.getRequest(
                    compoundURL,
                    process.env.VUE_APP_FINANCE_BASE_URL
                )

                this.expenditureRequests = data.data.data
                this.pageInfo.currentPage = data.data.current_page
                this.pageInfo.lastPage = data.data.last_page
                this.totalPages = data.data.total
                this.showDepartments = false
                this.loading = false
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async getAllDepartments() {
            try {
                this.showDepartments = true
                const { status, data } = await Request.getRequest(
                    'departments/all',
                    process.env.VUE_APP_FINANCE_BASE_URL
                )

                if (status === 200) {
                    this.departments = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async getExpenditureByDepartment(pageNo) {
            try {
                this.fetchingAllExpenditureRequests = false

                pageNo =
                    this.fetchingExpenditureRequestByDepartment &&
                    this.currentDepartment === this.$route.params.department
                        ? pageNo
                        : 1

                this.getExpenditureRequests('expenditure/requests', pageNo)

                this.fetchingExpenditureRequestByDepartment = true
                this.currentDepartment = this.$route.params.department
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async filterExpenditureByDate() {
            try {
                this.loading = true
                const startDate = this.moment(this.startDate).format('YYYY-MM-DD')
                const endDate = this.moment(this.endDate).format('YYYY-MM-DD')

                const { status, data } = await Request.getRequest(
                    `expenditure/requests?start_date=${startDate}&end_date=${endDate}`,
                    process.env.VUE_APP_FINANCE_BASE_URL
                )
                if (status >= 200 && status < 300) {
                    this.expenditureRequests = data.data.data
                    this.pageInfo.currentPage = data.data.current_page
                    this.pageInfo.lastPage = data.data.last_page
                    this.totalPages = data.data.total
                    this.loading = false
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async searchExpenditureRequests() {
            try {
                this.loading = true

                const { status, data } = await Request.getRequest(
                    `expenditure/requests?search=${this.searchTerm}`,
                    process.env.VUE_APP_FINANCE_BASE_URL
                )

                if (status >= 200 && status < 300) {
                    this.expenditureRequests = data.data.data
                    this.pageInfo.currentPage = data.data.current_page
                    this.pageInfo.lastPage = data.data.last_page
                    this.totalPages = data.data.total
                    this.loading = false
                    this.searchTerm = null
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>

<style scoped>
.expenditure-items-wrapper > div {
    width: calc(100% / 7);
}
</style>
